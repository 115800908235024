import { useState, useEffect, useContext, useRef } from "react";
import { Card, Button } from "react-bootstrap";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import Calendar from "./Calendar";
import { Spinner } from "react-bootstrap";
import { fetchContacts } from "../http/navLinksAPI";
import { Link, useNavigate } from "react-router-dom";
import ShareButton from "./UI/ShareButton";

const Contacts = observer(() => {
	const { contacts, contactInformation } = useContext(Context);
	const navigate = useNavigate();
	const [mapLink, setMapLink] = useState(
		"https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d789.83644002887!2d27.107449599697592!3d48.25123855243398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDjCsDE1JzA0LjciTiAyN8KwMDYnMjYuNiJF!5e0!3m2!1sru!2s!4v1735572426459!5m2!1sru!2s"
	);
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);

	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		fetchContacts()
			.then((data) => {
				contacts.setContacts(data);
			})
			.finally(() => setLoading(false));
	}, [contacts]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-md-row flex-column justify-content-center px-xl-4 border-bottom'>
			<div className='px-xl-5 px-2 col-xl-9 col-12 py-4'>
				<div className='block-title'>
					<span>Contacte</span>
				</div>
				<div
					className='d-flex flex-row flex-wrap justify-content-around test-print'
					ref={printRef}>
					{contacts.contacts.count > 0
						? contacts.contacts.rows.map((item, idx) => (
								<Card
									key={idx}
									style={{ width: "22rem" }}
									className='my-3 shadow border-0'>
									<Card.Title className='py-2 d-flex text-center text-white align-items-center justify-content-center bg-primary u-corner-radius truncate'>
										{item.title}
									</Card.Title>
									<Card.Body className='px-3'>
										<div className='d-flex flex-column'>
											<div className='d-flex flex-row align-items-center justify-content-between pb-3 bottom-shadow'>
												<i className='bi bi-geo-alt text-primary icon-2' />
												<span className='rounded text-primary px-2 py-1'>{item.location}</span>
												<Button
													className='btn btn-primary px-4'
													onClick={() => setMapLink(item.location_link)}>
													<span>Map</span>
												</Button>
											</div>

											<div className='d-flex flex-row align-items-center justify-content-between'>
												<i className='bi bi-person-vcard text-primary icon-2' />
												<span className='text-primary fw-bold px-2 py-1'>{item.responsible}</span>
											</div>

											{item.contacts_phones.map((sub_item, sub_idx) => (
												<div
													key={sub_idx}
													className='d-flex flex-row align-items-center justify-content-between'>
													<i className='bi bi-telephone text-primary icon-2' />
													<span className='text-primary px-2 py-1'>{sub_item.phone}</span>
												</div>
											))}

											{item.contacts_emails.map((sub_item, sub_idx) => (
												<div
													key={sub_idx}
													className='contacts-email d-flex flex-row align-items-center justify-content-between pb-2'>
													<i className='bi bi-envelope text-primary icon-2' />
													<span className='text-primary px-2 py-1'>{sub_item.email}</span>
												</div>
											))}

											<div className='d-flex flex-row align-items-center justify-content-between pt-3  top-shadow'>
												<i className='bi bi-clock text-primary icon-2' />
												<span className='border border-primary rounded text-primary px-2 py-1'>{item.days}</span>
												<span className='border border-primary rounded text-primary px-2 py-1'>{item.time}</span>
											</div>
										</div>
									</Card.Body>
								</Card>
						  ))
						: null}
				</div>

				<div className='map d-flex flex-column align-items-center'>
					<iframe
						title='locations-map'
						src={mapLink}
						style={{ width: "100%", minHeight: "50vh" }}
						className='rounded'
						loading='lazy'
						referrerPolicy='no-referrer-when-downgrade'></iframe>
				</div>

				<div className='map d-flex flex-column py-4'>
					<div className='block-title'>
						<span>Urmăriți-ne pe</span>
					</div>
					<div className='d-flex flex-xl-row flex-column justify-content-between'>
						<div className='d-flex flex-xl-row flex-column justify-content-around py-3'>
							<Link
								to={contactInformation.contactInformation.youtube_link}
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-youtube pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>YOUTUBE</span>
							</Link>
							<Link
								to={contactInformation.contactInformation.facebook_link}
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-facebook pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>FACEBOOK</span>
							</Link>
							<Link
								to={contactInformation.contactInformation.telegram_link}
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-telegram pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>TELEGRAM</span>
							</Link>
						</div>
						<div className='d-flex flex-row align-items-center justify-content-center ps-xl-1 pe-xl-5'>
							<i
								role='button'
								onClick={() => handlePrint()}
								className='bi bi-printer text-primary icon-3'
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/>
							<ShareButton />
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</div>
	);
});

export default Contacts;
