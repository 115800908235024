import React, { useEffect, useState, useRef } from "react";
import Calendar from "../components/Calendar";
import { Table } from "react-bootstrap";
import { getCECE } from "../http/ceceAPI";
import ShareButton from "../components/UI/ShareButton";

const CouncillorsList = () => {
	// const { councillors } = useContext(Context);
	const [councillors, setCouncillors] = useState([]);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		getCECE().then((data) => {
			groupByPartid(data.Consilieri);
		});
	}, []);

	const groupByPartid = async (list) => {
		const groupedCandidati = {};

		list.forEach((candidat, idx) => {
			const partidDenumire = candidat.Partid.Denumire;
			if (!groupedCandidati[partidDenumire]) {
				groupedCandidati[partidDenumire] = {
					Partid: candidat.Partid.Denumire,
					members: [],
				};
			}
			groupedCandidati[partidDenumire].members.push(candidat);
		});

		const sortedGroups = Object.values(groupedCandidati).sort((a, b) => b.members.length - a.members.length);

		return setCouncillors(sortedGroups);
	};

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div
					className='d-flex flex-column col-xl-8 px-xl-4'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Lista consilierilor Consiliului Local</span>
					</div>
					{councillors
						? councillors.map((item, idx) => (
								<div key={idx}>
									<div className='py-4 block-title text-center font-2'>
										<span className='text-primary'>{item.Partid}</span>
									</div>
									<Table
										key={"table-" + idx}
										bordered
										hover>
										<thead>
											<tr>
												<th className='bg-primary text-white'>#</th>
												<th className='bg-primary text-white'>Numele, Prenumele</th>
												<th className='bg-primary text-white'>Anul Nasterii</th>
											</tr>
										</thead>
										<tbody>
											{item.members.map((sub_item, sub_idx) => (
												<tr key={sub_idx}>
													<td>{sub_idx + 1}</td>
													<td>{sub_item.Candidat.Nume + " " + sub_item.Candidat.Prenume}</td>
													<td>{sub_item.Candidat.AnNastere}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
						  ))
						: null}
					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end w-100'>
						<i
							role='button'
							onClick={() => handlePrint()}
							className='bi bi-printer text-primary icon-3 px-4'
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<ShareButton />
					</div>
				</div>
				<Calendar />
			</div>
		</div>
		// <div className="d-flex flex-xl-row flex-column justify-content-around px-4 border-bottom">
		//   <div className="d-flex flex-column px-xl-5 py-4 col-xl-9 col-12">
		//     <div className="block-title pb-4">
		//       <span>Lista consilierilor Consiliului Raional Edineț</span>
		//     </div>

		//     <div className="d-flex flex-column justify-content-start shadow rounded px-5">
		//       <iframe
		//         id="widget_iframe idCecWidgetConsilieri15136"
		//         className="rounded"
		//         style={{
		//           borderWidth: 0,
		//           width: "100%",
		//           height: 1000,
		//           backgroundColor: "white",
		//           borderColor: "black",
		//           borderRadius: 0,
		//         }}
		//         src="http://serviciidate.cec.md/widgets/consilieri?id=idCecWidgetConsilieri115&HideAlegere=true&Tabs=About"
		//         scrolling="no"
		//       />
		//     </div>
		//   </div>
		//   <Calendar />
		// </div>
	);
};

export default CouncillorsList;
